<template lang="html">
  <div class="content-box">
    <div class="title-area">
      <h2 class="title-b">설정 / 현장관리 / 현장 계정 등록</h2>
    </div>

    <div class="form-box mt-sm">
      <div class="form-group-box">
        <div class="form-item row-type">
          <label class="form-title">고객사/운용부서<span class="requisite">*</span></label>
          <div class="form-element">
            <input type="text" placeholder="고객사/운용부서" v-model="companyName" class="element-input size-lg" />
          </div>
          <p class="mt-1 px-3 text-xxs text-red-600" v-if="companyName==''">필수 입력 사항 입니다.</p>
        </div>
        <div class="form-item row-type">
          <label class="form-title">아이디<span class="requisite">*</span></label>
          <div class="form-element change">
            <input type="text" placeholder="ID" v-model="adminID" class="element-input size-lg" :readonly="isCheckAdminID" />
            
            <button v-if="!isCheckAdminID" type="button" class="btn-change" @click="checkAdminIDDuplication()">중복검사</button>
            <button v-else type="button" class="btn-change" @click="resetAdminIDDuplication()">재설정</button>
          </div>
          <p class="mt-1 px-3 text-xxs text-red-600" v-if="adminID==''">필수 입력 사항 입니다.</p>
          <p class="mt-1 px-3 text-xxs text-red-600" v-if="adminID!='' && !isCheckAdminID">중복확인을 해 주세요.</p>
        </div>
        <div class="form-item row-type">
          <label class="form-title">비밀번호<span class="requisite">*</span></label>
          <div class="form-element">
            <input type="password" placeholder="비밀번호" v-model="adminPassword" class="element-input size-lg" />
          </div>
          <p class="mt-1 px-3 text-xxs text-red-600" v-if="adminPassword==''">필수 입력 사항 입니다.</p>
          <p class="mt-1 px-3 text-xxs text-red-600" v-if="!validatePasswd(adminPassword)">비밀번호를 확인해 주세요.(하나 이상의 문자,숫자,특수문자 8자리 이상)</p>
        </div>

        <div class="form-item row-type">
          <label class="form-title">비밀번호 확인<span class="requisite">*</span></label>
          <div class="form-element">
            <input type="password" placeholder="비밀번호 확인" v-model="adminPasswordCheck" class="element-input size-lg" />
          </div>
          <p class="mt-1 px-3 text-xxs text-red-600" v-if="adminPasswordCheck==''">필수 입력 사항 입니다.</p>
          <p class="mt-1 px-3 text-xxs text-red-600" v-if="adminPassword!=adminPasswordCheck">비밀번호를 확인해 주세요.</p>
        </div>
      </div>

      <div class="form-group-box">
        <div class="form-item row-type">
          <label class="form-title">담당자<span class="requisite">*</span></label>
          <div class="form-element">
            <input type="text" placeholder="담당자 명" v-model="adminName" class="element-input size-lg" />
          </div>
          <p class="mt-1 px-3 text-xxs text-red-600" v-if="adminName==''">필수 입력 사항 입니다.</p>
        </div>
        <div class="form-item row-type">
          <label class="form-title">휴대폰<span class="requisite">*</span></label>
          <div class="form-element">
            <input type="text" placeholder="휴대폰" v-model="adminPhone" @keyup="validatePhone()" class="element-input size-lg" />
          </div>
          <p class="mt-1 px-3 text-xxs text-red-600" v-if="adminPhone==''">필수 입력 사항 입니다.</p>
        </div>
        <div class="form-item row-type">
          <label class="form-title">이메일<span class="requisite">*</span></label>
          <div class="form-element">
            <input type="text" placeholder="이메일" v-model="adminEmail" @keyup="validateEmail()" class="element-input size-lg" />
          </div>
          <p class="mt-1 px-3 text-xxs text-red-600" v-if="adminEmail==''">필수 입력 사항 입니다.</p>
          <p class="mt-1 px-3 text-xxs text-red-600" v-if="adminEmail!='' && !isValidataEmail">이메일 형식을 확인해 주세요.</p>
        </div>
      </div>


      <div class="form-group-box">
        <div class="form-item row-type">
          <label class="form-title">탐지 문자 전송 시간<span class="requisite">*</span></label>
          <div class="form-element">
            <t-rich-select
              :options="detectMinuteList"
              class="border border-solid rounded-lg py-3 text-blueGray-500 border-gray-300 outline-none w-full"
              textAttribute="name"
              valueAttribute="id"
              :hideSearchBox="true"
              v-model="detectMinute"
            >
            </t-rich-select>
          </div>
          <p class="mt-1 px-3 text-xxs text-red-600" v-if="detectMinute==null">필수 입력 사항 입니다.</p>
        </div>
        <div class="form-item row-type">
          <label class="form-title">단말 로그 보관 기간<span class="requisite">*</span></label>
          <div class="form-element">
            <t-rich-select
              :options="deviceLogDayList"
              class="border border-solid rounded-lg py-3 text-blueGray-500 border-gray-300 outline-none w-full"
              textAttribute="name"
              valueAttribute="id"
              :hideSearchBox="true"
              v-model="deviceLogDay"
            >
            </t-rich-select>
          </div>
          <p class="mt-1 px-3 text-xxs text-red-600" v-if="deviceLogDay==null">필수 입력 사항 입니다.</p>
        </div>
      </div>



      <div class="form-group-box">
        <div class="form-item row-type size-wide">
          <label class="form-title">메모</label>
          <div class="form-element size-b">
            <textarea name="" class="element-textarea" v-model="adminDescription" placeholder="메모"></textarea>
          </div>
        </div>
      </div>
    </div>
    
    <div class="title-area mt-40">
      <p class="title-c">단말기 알림 SMS</p>
      <div class="element-switch">
        <input class="checkbox-input" type="checkbox" id="smaple51-1" v-model="isAlert" />
        <label class="checkbox-label" for="smaple51-1"><span class="for-a11y">Toggle Checkbox</span></label>
      </div>
    </div>

    <div class="choice-group mt-s">
      <div class="element-checkbox checkbox-text">
        <input class="radio-input" type="checkbox" id="checkbox1" v-model="alertDetect" />
        <label class="checkbox-label" for="checkbox1">신규 불법 카메라 탐지</label>
      </div>
      <div class="element-checkbox checkbox-text">
        <input class="radio-input" type="checkbox" id="checkbox2" v-model="alertOffline" />
        <label class="checkbox-label" for="checkbox2">단말기 오프라인</label>
      </div>
      <div class="element-checkbox checkbox-text">
        <input class="radio-input" type="checkbox" id="checkbox3" v-model="alertMemo" />
        <label class="checkbox-label" for="checkbox3">위치 정보에 단말메모 사용</label>
      </div>
    </div>

    <div class="note-group">
      <div class="note-item">
        <p class="note-title">불법 카메라 탐지 알림 문구</p>
        <div class="note-content">
          <span class="note-value">{관리구역명}</span>
          <span class="note-value">{상세위치}</span>
          <span class="note-value" v-if="alertMemo">{단말메모}</span>
          <span class="note-value">{단말기명}</span>
          <input type="text" class="w-3/5 py-2 px-1 outline-none" v-model="textDetect" placeholder="에서 신규 불법 카메라가 탐지되었습니다. 확인 바랍니다." />
        </div>
      </div>
      <div class="note-item">
        <p class="note-title">단말기 오프라인 알림 문구</p>
        <div class="note-content">
          <span class="note-value">{관리구역명}</span>
          <span class="note-value">{상세위치}</span>
          <span class="note-value" v-if="alertMemo">{단말메모}</span>
          <span class="note-value">{단말기명}</span>
          <input type="text" class="w-3/5 py-2 px-1 outline-none" v-model="textOffline" placeholder="가 오프라인 상태입니다. 확인 바랍니다." />
        </div>
      </div>
    </div>

    <div class="page-btn-area">
      <button type="button" class="element-btn bg-red size-lg" @click="cancelRegist()">취소</button>
      <!-- <button type="button" class="element-btn bg-blue size-lg" @click="companyRegist()" :disabled="checkRegistDisable()">저장</button> -->
      <button type="button" class="element-btn bg-blue size-lg" @click="companyRegist()">저장</button>
    </div>
  </div>
</template>

<script>
import { requestApi } from "@/utils/api"
import { mapGetters, mapActions } from "vuex"

import { encryptString } from '@/utils/config'
import { detectMinuteList, deviceLogDayList } from '@/references/config'

export default {
  created () {

  },
  computed: {
    ...mapGetters(["getIsOverlay"]),
  },
  data () {
    return {
      companyName: '',
      adminID: '',
      adminPassword: '',
      adminPasswordCheck: '',
      adminName: '',
      adminPhone: '',
      adminEmail: '',
      adminDescription: '',
      isAlert: true,
      alertDetect: false,
      alertOffline: false,
      alertMemo: false,
      textDetect: '에서 신규 불법 카메라가 탐지되었습니다. 확인 바랍니다.',
      textOffline: '가 오프라인 상태입니다. 확인 바랍니다.',
      isValidataEmail: false,

      isCheckAdminID: false,
      detectMinuteList,
      detectMinute: 0,
      deviceLogDayList,
      deviceLogDay: 0
    }
  },
  methods: {
    ...mapActions(["chgIsOverlay","chgIsAlert"]),
    async checkAdminIDDuplication () {
      if(this.adminID == '') {
        this.chgIsAlert({status:true,string:'ID를 입력해 주세요.'})
        
        return;
      }

      // const idReg = /^[A-Za-z0-9@$!%*#?&]{5,20}$/;
      const idReg = /^[A-Za-z0-9~!@#$%^&*()_+<>?]{5,20}$/;
      if(!idReg.test(this.adminID)) {
        // this.chgIsAlert({status:true,string:'아이디는 영문자로 시작하는 영문자 또는 숫자 5~20자 입니다.'})
        this.chgIsAlert({status:true,string:'아이디는 영문자 또는 숫자, 특수문자 5~20자 입니다.'})
        this.adminID = '';
        return;
      }
      
      let reqObj = {}
      reqObj.target = `/user/check/${this.adminID}`;
      reqObj.method = "get";
      reqObj.params = {};
      reqObj.data = {};
      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        if(result.msg.retData) {
          this.chgIsAlert({status:true,string:'ID 중복입니다.'})
        }
        else this.isCheckAdminID = true
      }

    },
    resetAdminIDDuplication () {
      this.isCheckAdminID = false
    },

    cancelRegist () {
      this.$router.push({name:'CompanyList'})
    },

    checkRegistDisable () {
      let retVal = true;
      if(this.adminID !== "" 
      && this.adminPassword !== ""
      && this.adminName !== ""
      && this.adminPhone !== ""
      && this.adminEmail !== ""
      && this.isValidataEmail
      && this.isCheckAdminID
      ) retVal = false;
      return retVal;
    },

    companyRegist () {
      if(this.companyName === '') {
        this.chgIsAlert({status:true,string:'고객사/운영부서를 입력해 주세요.'})
        return;
      }

      if(this.adminID === '') {
        this.chgIsAlert({status:true,string:'아이디를 입력해 주세요.'})
        return;
      }

      if(!this.isCheckAdminID) {
        this.chgIsAlert({status:true,string:'아이디 중복확인을 해주세요.'})
        return;
      }

      // let passwdReg = /^[a-zA-Z0-9]*$/;
      // let passwdReg = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
      // if(this.adminPassword == "" || !passwdReg.test(this.adminPassword)) {
      //   this.chgIsAlert({status:true,string:'비밀번호를 확인해 주세요.(하나 이상의 문자,숫자,특수문자 8자리 이상)'})
      //   return;
      // }

      if(this.adminPassword != this.adminPasswordCheck) {
        this.chgIsAlert({status:true,string:'비밀번호 확인이 맞지 않습니다.'})
        return;
      }

      if(!this.validatePasswd(this.adminPassword)) {
        this.chgIsAlert({status:true,string:'비밀번호를 확인해 주세요.(하나 이상의 문자,숫자,특수문자 8자리 이상)'})
        return
      }

      if(this.adminName === '') {
        this.chgIsAlert({status:true,string:'담당자 이름을 입력해 주세요.'})
        return;
      }

      let phoneReg = /^01([0|1|6|7|8|9])([0-9]{3,4})([0-9]{4})$/
      if(this.adminPhone === '' || !phoneReg.test(this.adminPhone)) {
        this.chgIsAlert({status:true,string:'휴대폰번호를 확인해 주세요.'})
        return;
      }

      if(this.adminEmail === '') {
        this.chgIsAlert({status:true,string:'이메일을 입력해 주세요.'})
        return;
      }

      if(!this.isValidataEmail) {
        this.chgIsAlert({status:true,string:'이메일 형식을 확인해 주세요.'})
        return;
      }

      this.compayRegistAction()
    },
    async compayRegistAction () {
      let reqObj = {}
      reqObj.target = "/admin/regist";
      reqObj.method = "post";
      reqObj.params = {};
      reqObj.data = {
        "alertDetect": this.alertDetect ? 'yes' : 'no',
        "alertOffline": this.alertOffline ? 'yes' : 'no',
        "alertMemo": this.alertMemo ? 'yes' : 'no',
        "companyName": this.companyName,
        "isAlert": this.isAlert ? 'yes' : 'no',
        "textDetect": this.textDetect,
        "textOffline": this.textOffline,
        "userDescription": this.adminDescription,
        "userEmail": encryptString(this.adminEmail),
        "userID": encryptString(this.adminID),
        "userName": encryptString(this.adminName),
        "userPassword": encryptString(this.adminPassword),
        "userPhone": encryptString(this.adminPhone),
        "detectMinute": this.detectMinute,
        "deviceLogDay": this.deviceLogDay,
      };
      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        this.chgIsAlert({status:true,string:'등록 했습니다.'})
        
        this.$router.push({name:'CompanyList'})
      }
    },

    validateEmail () {
      if (/^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i.test(this.adminEmail)) this.isValidataEmail = true
      else this.isValidataEmail = false
    },
    validatePhone () {
      this.adminPhone = this.adminPhone.replace(/[^0-9]/g, '');
    },
    validatePasswd (str) {
      // ~!@#$%^&*()_+<>?
      // let passwdReg = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
      let passwdReg = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[~!@#$%^&*()_+<>?])[A-Za-z\d~!@#$%^&*()_+<>?]{8,}$/;
      if(str == "" || !passwdReg.test(str)) return false
      else return true
    },
  }
  
}
</script>

<style lang="">
  
</style>